import React from 'react'
import LuxuryEscapesSmallLogo from './LuxuryEscapesSmallLogo'
import LuxuryEscapesLogo from './LuxuryEscapesLogo'
import styled from 'styled-components'
import clsx from 'clsx'
import { mediaQueryOnly, mediaQueryUp } from 'components/utils/breakpoint'

const HideableLogo = styled.div`
  &.hidden-at-mobile {
    ${mediaQueryOnly.mobile} { display: none; }
  }
  &.hidden-at-tablet {
    ${mediaQueryOnly.tablet} { display: none; }
  }
  &.hidden-at-desktop {
    ${mediaQueryOnly.desktop} { display: none; }
    ${mediaQueryOnly.largeDesktop} { display: none; }
  }
  &.hidden-at-extra-desktop-lg-and-up {
    ${mediaQueryUp.extraLargeDesktop} { display: none; }
  }
`

export interface ResponsiveLogoProps {
  /** @default icon+text */
  mobileVariant?: 'icon' | 'icon+text'
  /** @default icon+text */
  tabletVariant?: 'icon' | 'icon+text'
  /** @default icon+text */
  desktopVariant?: 'icon' | 'icon+text'
  /** @default icon+text */
  extraLargeDesktopVariant?: 'icon' | 'icon+text'
}

type Props = React.SVGAttributes<SVGSVGElement> & ResponsiveLogoProps

function ResponsiveLuxuryEscapesLogo(props: Props) {
  const {
    mobileVariant = 'icon+text',
    tabletVariant = 'icon+text',
    desktopVariant = 'icon+text',
    extraLargeDesktopVariant = 'icon+text',
    className,
    ...rest
  } = props

  return <>
    <HideableLogo
      as={LuxuryEscapesSmallLogo}
      {...rest}
      className={clsx(className, {
        'hidden-at-mobile': mobileVariant !== 'icon',
        'hidden-at-tablet': tabletVariant !== 'icon',
        'hidden-at-desktop': desktopVariant !== 'icon',
        'hidden-at-extra-desktop-lg-and-up': extraLargeDesktopVariant !== 'icon',
      })}
    />
    <HideableLogo
      as={LuxuryEscapesLogo}
      {...rest}
      className={clsx(className, {
        'hidden-at-mobile': mobileVariant !== 'icon+text',
        'hidden-at-tablet': tabletVariant !== 'icon+text',
        'hidden-at-desktop': desktopVariant !== 'icon+text',
        'hidden-at-extra-desktop-lg-and-up': extraLargeDesktopVariant !== 'icon+text',
      })}
    />
  </>
}

export default ResponsiveLuxuryEscapesLogo
